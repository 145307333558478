import axios from 'axios';
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VueAnalytics from "vue"
import VueGtag from "vue-gtag"

let token = localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : null;
axios.defaults.baseURL = 'https://tezmatka.in';
if (token) {
    axios.defaults.headers = {
        Authorization: "Bearer " + token,
    }
}
Vue.config.productionTip = false

 new Vue({
        router,axios,
        VueGtag,config: {
            id: "G-R4RD4M1RXT"
        },
        render: function(h) {
            return h(App);
        },

    }).$mount("#app");