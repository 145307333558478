<template>
 <div class="text-center">
      <div v-for="tableTime in tableTimes" :key="tableTime.id">
    <h3 class="py-1 bg-purple tableHead my-1">{{ tableTime.game_name }}</h3>
    <div class="row justify-content-center m-0">
      <div class="col-auto">
        <table border="1" class="table table-responsive table-striped">
          <tr>
            <th class="head-link text-dark text-center py-2 px-3">Time</th>
            <th class="head-link text-dark text-center py-2 px-3">Result</th>
            <th class="head-link text-dark text-center py-2 px-3">Time</th>
            <th class="head-link text-dark text-center py-2 px-3">Result</th>
          </tr>
          <tr v-for="(times, index) in tableTime.times[0]" :key="index">
              <td class="table_content">{{ times }}</td>
              <td class="table_content">{{tableTime.currentGame[0][index]}}</td>
              <td class="table_content">{{ tableTime.times[1][index] }}</td>
              <td class="table_content">{{tableTime.currentGame[1][index]}}</td>
          </tr>

        </table>
      </div>
    </div>
    <router-link class="btn-neon py-2 panelChartTitle btn btn-block w-100"
     :to="{name:'table_panel',params:{id:tableTime.id}}" >Panel Chart</router-link>
  </div>
 </div>
</template>

<script>
export default {
  props: {
    tableTimes: null,
  },

};
</script>

<style>
.tableHead {
  font-family: Bangers, sans-serif;
}
.table_content{
  font-family: "Aclonica";
  font-size: 0.7rem;
}
.panelChartTitle {
  font-family: Aclonica, sans-serif;
}
</style>
