<template>
  <div>
    <nav class="sb-topnav navbar navbar-expand navbar-light bg-light">
            <!-- Navbar Brand-->
            <router-link class="btn btn-sm btn-primary m-2" to="/">Home</router-link>
            <router-link class="btn btn-sm btn-success m-2" to="/client/dashboard">Dashboard</router-link>
            <span class="btn btn-danger btn-sm m-2" @click="logout()">logout</span>
            <!-- Sidebar Toggle-->
            <!-- <button class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i class="fas fa-bars"></i></button> -->
            <!-- Navbar-->
            <ul class="navbar-nav ms-auto me-0 me-md-3 my-2 my-md-0">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-user fa-fw"></i></a>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        <li><router-link class="dropdown-item" to="/client/profile">Settings</router-link></li>
                        <li><hr class="dropdown-divider" /></li>
                        <li><a class="dropdown-item" @click="logout()">Logout</a></li>
                    </ul>
                </li>
            </ul>
        </nav>

        <div id="layoutSidenav">
            <div id="layoutSidenav_nav">
                <nav class="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion">
                 
                </nav>
            </div>
            <div class="bg-light" id="layoutSidenav_content">
                <main>
                 <slot/>
                </main>
            </div>
        </div>
  </div>
</template>

<script>
import axiosConfig from '../../axiosConfig';
import axiosObject from '../../axiosHandler';
let accessToken = localStorage.getItem("accessToken");

let config = {
  headers: {
    Authorization: "Bearer " + accessToken,
  },
};

export default {
    data(){
        return{
            user:null
        }
    },

    created(){
         this.getUser()

    },
  methods: {
    getUser() {

      axiosObject.get("/api/profile",axiosConfig)
        .then(function (response) {
              this.user = response.data
        }.bind(this))
        .catch(function (error) {
              console.log(error)
              //self.$router.replace({name:'login'})

        });
    },
     logout(){

        axiosObject.post('/api/logout',config).then(function(response){
            if (response.status == 200) {
              localStorage.removeItem('accessToken')
              localStorage.removeItem('isLoggedIn')
              localStorage.removeItem('role')
            }
            this.$router.replace({name:'home'})
        }.bind(this)).catch(err=>{
          if (err.response.status == 401 || err.response.status == 403 ) {
              localStorage.removeItem('accessToken')
              localStorage.removeItem('isLoggedIn')
              localStorage.removeItem('role')
              window.location.reload();
            }
        })
    },

  },
};
</script>

<style type="css" scoped>
body{
    background: white !important;
    color:rgb(0, 0, 0)
}

.modal-container {

  background-color:white !important;
  color: black !important;
}
</style>
