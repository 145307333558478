<template>
<div>
   <component :is="layout">
       <div class="row m-3 p-2 m-1 justify-content-around">
          <div class="col-md-3 text-center py-5 p-2 m-1 card bg-primary text-white">
              <router-link class="text-white" to="/admin/daily_games">Daily Games</router-link>
          </div>
           <div class="col-md-3 text-center py-5 p-2 m-1 card bg-primary text-white">
              <router-link class="text-white" to="/admin/table_games">Table Games</router-link>
          </div>
           <div class="col-md-3 text-center py-5 p-2 m-1 card bg-primary text-white">
              <router-link class="text-white" :to="{name:'admin_guessing_table'}">Guessing Table</router-link>
          </div>
           <div class="col-md-3 text-center py-5 p-2 m-1 card bg-primary text-white">
             <router-link class="text-white" to="/admin/notifier">Notifier</router-link>

          </div>
           <div class="col-md-3 text-center py-5 p-2 m-1 card bg-primary text-white">
            <router-link class="text-white" :to="{name:'admin_client'}">Clients</router-link>

          </div>
           <div class="col-md-3 text-center py-5 p-2 m-1 card bg-primary text-white">
            <router-link class="text-white" :to="{name:'admin_messages'}">Messages</router-link>
          </div>
       </div>
   </component>

</div>
</template>

<script>
export default {
 computed:{
     layout(){
         return (this.$route.meta.layout)
     }
 }
}
</script>

<style>

</style>
