import axios from "axios";

const axiosObject = axios

axiosObject.defaults.baseURL = 'https://tezmatka.in';
let token = localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : null;
if (token) {
    axios.defaults.headers = {
        Authorization: "Bearer " + token,
    }
}

axiosObject.defaults.headers = {
    'Accept' : "application/json"
}

export default axiosObject;