<template>
  <div>
      <section class="mb-4">

    <!--Section heading-->
    <h2 class="h1-responsive font-weight-bold text-center my-4">Contact us</h2>
    <!--Section description-->
    <p class="text-center w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within
        a matter of hours to help you.</p>
     <span v-if="message">
          <p class="text-center alert-success">{{ message }}</p>
        </span>
    <div class="row">

        <!--Grid column-->
        <div class="col-md-6 offset-md-3 mb-md-0 mb-5">
            <form id="contact-form" name="contact-form" action="mail.php" method="POST">

                <!--Grid row-->
                <div class="row">

                    <!--Grid column-->
                    <div class="col-md-6">
                        <div class="md-form mb-0">
                            <input type="text" v-model="form.name" id="name" name="name" class="form-control">
                            <label for="name" class="">Your name</label>
                        </div>
                    </div>
                    <!--Grid column-->

                    <!--Grid column-->
                    <div class="col-md-6">
                        <div class="md-form mb-0">
                            <input v-model="form.email" type="text" id="email" name="email" class="form-control">
                            <label for="email" class="">Your email</label>
                        </div>
                    </div>
                    <!--Grid column-->

                </div>
                <!--Grid row-->

                <!--Grid row-->
                <div class="row">
                    <div class="col-md-12">
                        <div class="md-form mb-0">
                            <input v-model="form.subject" type="text" id="subject" name="subject" class="form-control">
                            <label for="subject" class="">Subject</label>
                        </div>
                    </div>
                </div>
                <!--Grid row-->

                <!--Grid row-->
                <div class="row">

                    <!--Grid column-->
                    <div class="col-md-12">

                        <div class="md-form">
                            <textarea v-model="form.message" type="text" id="message" name="message" rows="2" class="form-control md-textarea"></textarea>
                            <label for="message">Your message</label>
                        </div>

                    </div>
                </div>
                <!--Grid row-->

            </form>

            <div class="text-center">
                <button @click="store()" class="btn btn-primary">Send</button>
            </div>
            <div class="status"></div>
        </div>
        <!--Grid column-->

    </div>

</section>
  </div>
</template>

<script>
import axiosObject from '../axiosHandler'
import axiosConfig from '../axiosConfig'
export default {
    data(){
      return{
          form:{},
          message:null
      }
    },
  methods:{
      store()
      {
          axiosObject.post('/api/contact_us',this.form,axiosConfig).then(function(response){
              this.message = response.data.message
          }.bind(this)).catch(function(error){
             alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          }
          })
      }
  }
}
</script>

<style>
  .contact-form {
  margin: 0 auto;
  padding-top: 20vh;
}
</style>
