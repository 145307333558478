<template>
  <div class="">
    <div class="header py-1">
      <h1 class="text-center p-0">Results</h1>
    </div>
    <div class="container">
      <div class="row justify-content-between">
        <div
          :style="dailyGame.color"
          v-for="dailyGame in dailyGames"
          :key="dailyGame.id"
          class="shadow result col-md-4 p-2"
        >
          <div class="text-center">
            <h4 class="title text-center">{{ dailyGame.name }}</h4>

            <h3 class="numbers text-center">{{ dailyGame.result }}</h3>
          </div>
          <div
            class="d-flex text-center justify-content-between text-center align-items-center"
          >
            <router-link
              class="btn btn-sm jodi-btn"
              :to="{ name: 'jodi_panel', params: { id: dailyGame.id } }"
              >jodi</router-link
            >
            <span class="text-center time-text text-warning">{{
              dailyGame.time
            }}</span>
            <router-link
              class="btn btn-sm panel-btn"
              :to="{ name: 'panel', params: { id: dailyGame.id } }"
              >panel</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    dailyGames: null,
  },

  methods: {
    fetchDailyGame(id, index) {
      axiosObject.get("/api/daily_game/" + id).then(
        function (response) {
          this.dailyGames[index].result = response.data.daily_game.result;
          this.$forceUpdate();
        }.bind(this)
      );
    },
  },
};
</script>

<style>
.resultHeader {
  background-color: #6739b7;
  font-family: "Bangers";
}
.header {
  color: white;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: sans-serif;
  background-color: #6739b7;
}
.result {
  background-image: linear-gradient(
    to right top,
    #3a2066,
    #422575,
    #4a2983,
    #532e93,
    #5b33a2,
    #5b33a2,
    #5b33a1,
    #5b33a1,
    #522e91,
    #4a2a82,
    #412573,
    #392164
  );
  margin-top: 8px;
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
.title {
  padding-top: 7px;
  color: white !important;
  font-family: sans-serif;
  font-size: 20px;
}
.numbers {
  font-size: 20px;
  margin-bottom: 2px;
  font-weight: bold;
  color: white;
}
.panel-btn {
  color: #fff;
  background-image: linear-gradient(
    to right top,
    #3a2066,
    #422575,
    #4a2983,
    #532e93,
    #5b33a2,
    #5b33a2,
    #5b33a1,
    #5b33a1,
    #522e91,
    #4a2a82,
    #412573,
    #392164
  );
  border-radius: 12px;
  border: white 0.01rem solid;
}
.btn-purple {
  background-color: red;
  color: rgb(255, 255, 255);
}
.jodi-btn {
  color: #fff;
  background-image: linear-gradient(
    to right top,
    #3a2066,
    #422575,
    #4a2983,
    #532e93,
    #5b33a2,
    #5b33a2,
    #5b33a1,
    #5b33a1,
    #522e91,
    #4a2a82,
    #412573,
    #392164
  );
  border-radius: 12px;
  border: white 0.01rem solid;
}
.time-text {
  font-size: 0.8rem;
}
</style>
