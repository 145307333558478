<template>
  <div class="text-center py-2">
      <h2 class="bg-red panelHead">Panel Chart</h2>
      <div v-for="dailyGame  in dailyGames" :key="dailyGame.id" class="panelChart my-1">
      <router-link class="panelChartText" :to="{name: 'panel', params: { id: dailyGame.id }}"><h3>{{ dailyGame.name }}</h3></router-link>
      </div>
  </div>
</template>

<script>
export default {
  props:{
      dailyGames:null,
  }
}
</script>

<style scoped>
.panelChartText{
    font-family: Aclonica, sans-serif;
    padding-top: 8px;
    color: rgb(255, 255, 255);
    font-style: italic;
    font-weight: bold;

}

.panelChart{

    padding-top: 10px;
    background:#6739B7;
    padding-bottom: 10px;
}
.panelHead{
    color: white;
    margin-top: 10px;
    padding-top:10px;
    padding-bottom: 10px;
    font-family: 'Architects Daughter', cursive;
    font-weight: bolder;
}


</style>
