<template>
  <div class="border my-3 border-primary">
    <span>{{ footer }}</span>
    <div class="text-center my-2">
      <router-link class="btn btn-danger" :to="{ path:'/privacy-policy'}"
        >Privacy Policy</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    footer: null,
  },
};
</script>

<style>
</style>
