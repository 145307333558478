export default function auth ({ next, store }){
    if(!localStorage.isLoggedIn){
        return next({
           name: 'login'
        })
    }
    else if(localStorage.role == "client"){
        
        return next({
            name: 'client_dashboard'
         })
    }
   
    return next()
   }