export default function guest ({ next, store }){
    if(localStorage.isLoggedIn){
        if(localStorage.role == "client")
        {
            return next({
                name: 'client_dashboard'
             })
        }
        else if(localStorage.role =="admin")
        {
            return next({
                name: 'admin_dashboard'
             })
        }
    }
   
    return next()
   }