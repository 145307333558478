import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../components/Login.vue";
import Home from "../components/Home.vue";
import ContactUs from "../components/ContactUs.vue";
import Dashboard from "../components/Admin/Dashboard.vue";
import Admin from "../components/Admin/Admin.vue";
import Layout from "../components/Client/Layout.vue";
import guest from "./middlewares/guest"
import client_auth from "./middlewares/client_auth"
import admin_auth from "./middlewares/admin_auth"
import middlewarePipeline from './middlewarePipeline'
Vue.use(VueRouter)

const routes = [{
        path: "/",
        component: Home,
        name: "home",
    },
    {
        path: "/privacy-policy",
        component: () =>
            import ("../components/Home/PrivacyPolicy.vue"),
        name: "privacy-policy",
    },
    {
        path: "/panel/:id",
        name: "panel",
        component: () =>
            import ("../components/Home/DailyGamePanel.vue"),
    },
    {
        path: "/jodi_panel/:id",
        name: "jodi_panel",
        component: () =>
            import ("../components/Home/DailyGameJodiPanel.vue"),
    },
    {
        path: "/table_panel/:id",
        name: "table_panel",
        component: () =>
            import ("../components/Home/TableGamePanel.vue"),
    },
    {
        path: "/login",
        component: Login,
        name: "login",
        meta: {
            middleware: [
                guest
            ]
        },
    },
    {
        path: "/contact-us",
        component: ContactUs,
    },

    //admin routes
    {
        path: "/admin/dashboard",
        component: Dashboard,
        meta: { layout: Admin ,middleware: [admin_auth]},
        name: "admin_dashboard",
        
    },

    {
        path: "/admin/clients",
        component: () =>
            import ("../components/Admin/Client.vue"),
        meta: { layout: Admin,middleware: [admin_auth] },
        name: "admin_client",
        
    },

    {
        path: "/admin/daily_games",
        component: () =>
            import ("../components/Admin/DailyGame.vue"),
        meta: { layout: Admin,middleware: [admin_auth]  },
        name: "admin_daily_games",
       
    },
    {
        path: "/admin/table_games",
        component: () =>
            import ("../components/Admin/TableGame.vue"),
        meta: { layout: Admin ,middleware: [admin_auth] },
        name: "admin_table_games",
        
    },
    {
        path: "/admin/guessing_table",
        component: () =>
            import ("../components/Admin/GuessingTable.vue"),
        meta: { layout: Admin ,middleware: [admin_auth] },
        name: "admin_guessing_table",
        
    },

    {
        path: "/admin/notifier",
        component: () =>
            import ("../components/Admin/Notifiers.vue"),
        meta: { layout: Admin,middleware: [admin_auth]  },
        name: "admin_notifier",
        
    },

    {
        path: "/admin/messages",
        component: () =>
            import ("../components/Admin/Messages.vue"),
        meta: { layout: Admin,middleware: [admin_auth]  },
        name: "admin_messages",
        
    },

    {
        path: "/admin/profile",
        component: () =>
            import ("../components/Admin/Profile.vue"),
        name: "admin_profile",
        meta: { layout: Admin ,middleware: [admin_auth] },
        
    },

    //client routes
    {
        path: "/client/dashboard",
        component: () =>
            import ("../components/Client/Dashboard.vue"),
        name: "client_dashboard",
        meta: { layout: Layout ,middleware: [client_auth]},
        
    },

    {
        path: "/client/daily_game",
        component: () =>
            import ("../components/Client/DailyGame.vue"),
        name: "client_daily_game",
        meta: { layout: Layout ,middleware: [client_auth]},
        
    },
    {
        path: "/client/table_game",
        component: () =>
            import ("../components/Client/TableGame.vue"),
        name: "client_table_game",
        meta: { layout: Layout ,middleware: [client_auth]},

    },
    {
        path: "/client/profile",
        component: () =>
            import ("../components/Client/Profile.vue"),
        name: "client_profile",
        meta: { layout: Layout,middleware: [client_auth] },

    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next
    }


    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })

})

export default router