<template>
  <div>
    <div class="liveResult shadow py-1">
      <h1 class="text-center">Live Results <i @click="refresh"> <img height="24" width="24" src="../../assets/icons/refresh.svg" alt="" srcset=""></i> </h1>
    </div>
    <div class="container">
      <div class="row justify-content-around py-2">
        
       <div v-for="liveGame in liveGames" :key="liveGame.id" class="text-center shadow rounded--live-card card col-md-4">
     <h4 class="liveResultTitle">{{ liveGame.name }}</h4>
     <h4 class="liveResultNumbers">{{ liveGame.result }}</h4>
   </div>
    </div>
    </div>
  </div>
</template>

<script>

export default {
props:{
  liveGames:null
},
methods:{
  refresh(){
    this.$parent.$emit('refresh', 'true');
  }
}

};
</script>

<style>
.liveResult {
  color: white;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: "Architects Daughter", cursive;
  background-color: #6739B7;
}
.liveResultTitle{
    padding-top: 7px;
    color: #6d7ae7;
    margin-bottom: -6px;
    font-weight: bold;
    font-family: "Aclonica";
    font-size: 20px;
}
.liveResultNumbers{
    font-family: 'Architects';
    font-weight: bold;
    font-size: 20px;
}
.rounded--live-card{
  border-radius: 12px;
}
</style>
