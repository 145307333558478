import axios from "axios";


const setAuthHandler = (token) => {

    if (token) {
        axios.defaults.headers = {
            Authorization: "Bearer " + token,
        }
    } else {
        delete axios.defaults.headers.Authorization;
    }
};

export default setAuthHandler;