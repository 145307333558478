<template>
<div class="signup-form">
    <div class="row">
      <div class="border shadow rounded card p-0 text-dark pb-5 rounded col-md-4 offset-md-4">
        <h1 class="text-center rounded  liveResult m-2 p-2">EMatka</h1>
        <p class="alert alert-danger mx-3 my-2" v-if="msg">
          {{ msg }}
        </p>
        <form>
          <div class="form-group px-3">
            <label for="inputEmail">Email</label>
            <input
              v-model="form.email"
              type="email"
              class="form-control"
              id="inputEmail"
              placeholder="Email"
            />
          </div>
          <div class="form-group px-3">
            <label for="inputPassword">Password</label>
            <input
              v-model="form.password"
              type="password"
              class="form-control"
              id="inputPassword"
              placeholder="Password"
            />
          </div>

          <div class="p-3">
            <button @click="login" type="button" class="btn w-100 btn-success">
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import setAuthHandler from "../axiosHeader.js";
import axiosObject from "../axiosHandler";
import axiosConfig from '../axiosConfig.js';
export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
     msg:null
    };
  },

  methods: {
    login() {

      axiosObject.post("/api/login", this.form,axiosConfig)
        
        .then(function (response) {
           
          if (response.status == 201) {
             localStorage.setItem('accessToken', response.data.token);
             localStorage.setItem('isLoggedIn',true);
             localStorage.setItem('user',JSON.stringify(response.data.user))
              setAuthHandler(response.data.token)
            if(response.data.user.role_id ==1)
            {
                localStorage.setItem('role','admin');
               this.$router.replace({name:'admin_dashboard'});
            }
            else if(response.data.user.role_id == 2)
            {
                localStorage.setItem('role','client');
               this.$router.replace({name:'client_dashboard'});

            }
          }
          else if(response.status == 401)
          {
            this.msg = response.data.msg
          }
        }.bind(this))
        .catch(function (error) {
          this.msg = error.response.data.msg
        }.bind(this));
    },
  },
};
</script>

<style>
.signup-form {
  margin: 0 auto;
  padding-top: 20vh;

}
</style>
