<template>
  <div>

      <Header :notifiers="notifiers"></Header>
      <live-results :liveGames="liveGames"></live-results>
      <daily-games :dailyGames="dailyGames"></daily-games>
      <table-games :tableTimes="tableTimes"></table-games>
      <panel-chart :dailyGames="dailyGames"></panel-chart>
      <jodi-panel-chart :dailyGames="dailyGames"></jodi-panel-chart>
      <guessing-table :guessingTables="guessingTables"></guessing-table>
      <div class="refresh-btn-container"><button class="refresh-btn" @click="refreshPage">Refresh</button></div>

      <Footer :footer="notifiers.footer"></Footer>
  </div>
</template>

<script>
import Header from "./Home/Header.vue";
import LiveResults from "./Home/LiveResults.vue";
import DailyGames from "./Home/DailyGames.vue";
import TableGames from "./Home/TableGames.vue";
import PanelChart from "./Home/PanelChart.vue";
import JodiPanelChart from "./Home/JodiPanelChart.vue";
import GuessingTable from "./Home/GuessingTable.vue";
import Footer from './Home/Footer.vue';
import axiosObject from "../axiosHandler";
export default {

    components:{
      Header,
      LiveResults,
      DailyGames,
      TableGames,
      JodiPanelChart,
      PanelChart,
      GuessingTable,
      Footer
    }
    ,

    data() {
    return {
      dailyGames: Object,
      liveGames:Object,
      tableTimes:Object,
      guessingTables:Object,
      notifiers: Object
    };
  },
  created(){
    this.getDailyGames()
    this.$on('refresh', section => {
            this.getDailyGames()
          });
    
  },
  methods: {
      getDailyGames(){
        //'/api/home?exclude=Tez Mumbai,Tez Mumbai Night'
         axiosObject.get('/api/home').then(function(response){
           this.dailyGames = response.data.daily_games
           this.liveGames = response.data.live_games
           this.tableTimes = response.data.table_times
           this.guessingTables = response.data.guessing_tables
           this.notifiers = response.data.notifiers
         }.bind(this)).catch(function(error){
             console.log(error)
         });
      },
       refreshPage(){
        window.location.reload();
      }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Aclonica&family=Bangers&family=Architects+Daughter&family=Architects&display=swap');
 
 .logo {
    width: 300px;
    height: 70px;
    margin-top: 18px;
}
.bg-red{
   background-color: red;
}
.rounded-pill-top{
  border-top-left-radius: 50rem;
  border-top-right-radius: 50rem;
}
.rounded-pill-top-head{
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}
.rounded-pill-bottom{
  border-bottom-left-radius: 20rem;
  border-bottom-right-radius: 20rem;
}

.bg-purple {
    background-color: #6739B7;
    color: white;
}
.refresh-btn-container {
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    width: 1%;
    height: 100%;
    background: transparent;
}
.refresh-btn {
    position: absolute;
    top: 90%;
    left: -65px;
    z-index: 998;
    width: 68px;
    height: 35px;
    font-size: 13px;
        background-image: linear-gradient(to right top, #3a2066, #422575, #4a2983, #532e93, #5b33a2, #5b33a2, #5b33a1, #5b33a1, #522e91, #4a2a82, #412573, #392164);
    border-radius: 25px;
    margin: auto;
    outline: none;
    border-radius: 6px;
    box-shadow: 0 4px 8px #0003, 0 6px 20px #00000030;
    color: #fff;
}
</style>
