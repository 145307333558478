<template>
  <div>
    <nav class="w-100 fixed-top bg-purple">
      <div class="container-fluid d-flex align-items-center justify-content-between">
        <a class="navbar-brand head-link" href="#"
          ><img width="120vw" class="img-fluid" src="img/logo.png"
        /></a>

        <div class="d-flex justify-content-end align-items-center">
          <router-link
            v-if="storage.isLoggedIn && storage.role == 'admin'"
            to="/admin/dashboard"
            class="nav-link head-link"
            >{{ JSON.parse(storage.getItem("user")).name }}</router-link
          >

          <router-link
            v-else-if="storage.isLoggedIn && storage.role == 'client'"
            to="/client/dashboard"
            class="nav-link head-link"
            >{{ JSON.parse(storage.getItem("user")).name }}</router-link
          >
          <router-link v-else class="nav-link head-link" to="login"
            >Login</router-link
          >

          <a class="nav-link head-link" href="https://wa.me/917063366676"
            ><img src="img/whatsappLogo.png" width="30px" height="30px"></a
          >
        </div>
      </div>
    </nav>

    <div class="pt-5">
      <div class="my-3 py-2 container shadow text-center">
        <div class="bg-purple p-3 lead" v-html="notifiers.marquee"></div>
        <div class="lead text-monospace text-wrap" v-html="notifiers.notification">
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-around pb-5">
      <a v-if="notifiers.whatsapp_community" :href="notifiers.whatsapp_community"><img height="40px" src="/img/whatsapp_button.png" alt="" srcset=""></a>
      <a v-if="notifiers.online_game" :href="notifiers.online_game" class="btn btn-purple">Online Game</a>
      <a v-if="notifiers.cricket_game" :href="notifiers.cricket_game" class="btn btn-purple">Cricket Game</a>
    </div>
    <img v-if="notifiers.image" class="image" :src="'/images/'+notifiers.image" alt="" srcset="">
  </div>
</template>

<script>
export default {
  props: {
    notifiers: null,
  },
  data() {
    return {
      storage: localStorage,
    };
  },
  mounted() {},
};
</script>

<style>
.head-link {
  font-family: "Aclonica";
  color: white;
  font-size: 14px;
  padding:4px
}
.image{
  height:40vh;
  width:100vw;
}

.marquee {
  background: #f95700ff;
  padding: 20;
  font-family: "Bangers";
}
.notification {
  background: #6a44dd;
}
.btn-purple{
  background:linear-gradient(
    to right top,
    #3a2066,
    #422575,
    #4a2983,
    #532e93,
    #5b33a2,
    #5b33a2,
    #5b33a1,
    #5b33a1,
    #522e91,
    #4a2a82,
    #412573,
    #392164
  ) !important;
  
  margin: 2px !important;
  
}
</style>
